import { useOutletContext } from "@remix-run/react";

export default function Footer() {
  const { brand, user } = useOutletContext();
  // Handle error state
  if (brand.error) {
    return <p>Error loading brand: {brand.message}</p>;
  }

  // Handle empty data state
  if (brand.loaded && brand.success && !brand.data) {
    return <p>No brand settings available.</p>;
  }

  return (
    <footer className="bg-white hidden lg:block">
      <hr />
      <div className="max-w-[1506px] mx-auto py-[30px] lg:py-[40px] px-[10px] lg:px-8 2xl:px-0 mt-0 lg:mt-12">
        <div className="grid grid-cols-12 gap-4 lg:gap-8 auto-cols-fr w-full items-center">
          <div className="col-span-12 lg:col-span-4">
            <ul className="pl-0">
              <h4 className="text-lg mb-4">Help</h4>
              <li className="mb-2">
                <a href="/terms-and-conditions" className="text-[#777b80]">
                  Terms and conditions
                </a>
              </li>
              <li className="mb-2">
                <a href="/privacy-policy" className="text-[#777b80]">
                  Privacy Policy
                </a>
              </li>
              <li className="mb-2">
                <a href="/refund-policy" className="text-[#777b80]">
                  Refund Policy
                </a>
              </li>

              {user?.business === "nationwide" ? (
                <li className="mb-2">
                  <a href="/shipping-policy" className="text-[#777b80]">
                    Shipping Policy
                  </a>
                </li>
              ) : (
                <li className="mb-2">
                  <a href="/local-delivery-policy" className="text-[#777b80]">
                    Local Delivery Policy
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <ul>
              <h4 className="text-lg mb-4">Contact Us</h4>
              <li className="mb-2">
                <p>
                  <span className="text-[#777b80]">
                    {brand.data.contact_number}
                  </span>
                </p>
              </li>
              <li className="mb-2">
                <p>
                  <a
                    target="_blank"
                    href={`mailto:${brand.data.contact_email}`}
                    rel="noreferrer"
                    className="text-[#777b80]"
                  >
                    {brand.data.contact_email}
                  </a>
                </p>
              </li>
              <li>
                <p className="text-[#777b80]">{brand.data.address}</p>
              </li>
            </ul>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <div>
              <h4 className="text-lg mb-4">Follow us on</h4>
              <p className="text-[#777b80] mb-4">
                Be the first to catch our sales! Follow us on social media for
                exclusive deals and offers.{" "}
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/ourismarket"
                  >
                    <svg width="20" height="20" viewBox="0 0 20 20">
                      <path d="M13.55,1H6.46C3.45,1,1,3.44,1,6.44v7.12c0,3,2.45,5.44,5.46,5.44h7.08c3.02,0,5.46-2.44,5.46-5.44V6.44 C19.01,3.44,16.56,1,13.55,1z M17.5,14c0,1.93-1.57,3.5-3.5,3.5H6c-1.93,0-3.5-1.57-3.5-3.5V6c0-1.93,1.57-3.5,3.5-3.5h8 c1.93,0,3.5,1.57,3.5,3.5V14z"></path>
                      <circle cx="14.87" cy="5.26" r="1.09"></circle>
                      <path d="M10.03,5.45c-2.55,0-4.63,2.06-4.63,4.6c0,2.55,2.07,4.61,4.63,4.61c2.56,0,4.63-2.061,4.63-4.61 C14.65,7.51,12.58,5.45,10.03,5.45L10.03,5.45L10.03,5.45z M10.08,13c-1.66,0-3-1.34-3-2.99c0-1.65,1.34-2.99,3-2.99s3,1.34,3,2.99 C13.08,11.66,11.74,13,10.08,13L10.08,13L10.08,13z"></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <hr />
        <p className="text-center px-3 mt-12 mb-12 text-[#777b80]">
          © Copyright {new Date().getFullYear()} Ouri's Market. All Rights
          Reserved. Version 1.12.4
        </p>
      </div>
    </footer>
  );
}
